import { useMemo } from 'react';
import { ApolloClient, ApolloProvider, NormalizedCacheObject } from '@apollo/client';
import { getDataFromTree } from '@apollo/client/react/ssr';
import initApolloClient from './initApolloClient';
import { initializeApollo, APOLLO_STATE_PROP_NAME } from './initializeApolloClient';
import AppWrapper from 'pages/AppWrapper';

/**
 * Creates and provides the apolloContext
 * to a next.js PageTree. Use it by wrapping
 * your PageComponent via HOC pattern.
 * @param {Function|Class} PageComponent
 * @param {Object} [config]
 * @param {Boolean} [config.ssr=true]
 */
/* eslint-disable no-console */
/* eslint-disable react/prop-types */
const ENV = process.env.NODE_ENV;

export function withApollo(PageComponent, { ssr = true, skipPageProps = false } = {}) {
  const WithApollo = ({
    apolloClient, apolloState, ...pageProps
  }) => {
    const client = apolloClient || initApolloClient(undefined, apolloState);
    return (
      <ApolloProvider client={client}>
        <AppWrapper>
          <PageComponent {...pageProps} />
        </AppWrapper>
      </ApolloProvider>
    );
  };

  // Set the correct displayName in development
  if (ENV !== 'production') {
    const displayName = PageComponent.displayName || PageComponent.name || 'Component';

    if (displayName === 'App') {
      console.warn('This withApollo HOC only works with PageComponents.');
    }

    WithApollo.displayName = `withApollo(${displayName})`;
  }

  if (ssr || !skipPageProps || PageComponent.getInitialProps) {
    WithApollo.getInitialProps = async (ctx) => {
      const { AppTree } = ctx;

      // Initialize ApolloClient, add it to the ctx object so
      // we can use it in `PageComponent.getInitialProp`.
      ctx.apolloClient = await initApolloClient({
        res: ctx.res,
        req: ctx.req
      });

      const { apolloClient } = ctx;

      // Run wrapped getInitialProps methods
      let pageProps = {};
      if (PageComponent.getInitialProps) {
        pageProps = await PageComponent.getInitialProps(ctx);
      }
      // Only on the server:
      if (typeof window !== 'undefined') {
        // When redirecting, the response is finished.
        // No point in continuing to render
        if (ctx.res && ctx.res.finished) {
          return pageProps;
        }

        // Only if ssr is enabled
        if (ssr) {
          try {
            // Run all GraphQL queries
            await getDataFromTree(
              <AppTree
                pageProps={{
                  ...pageProps,
                  apolloClient
                }}
              />
            );
          } catch (error) {
            // Prevent Apollo Client GraphQL errors from crashing SSR.
            // Handle them in components via the data.error prop:
            // https://www.apollographql.com/docs/react/api/react-apollo.html#graphql-query-data-error
            console.error('Error while running `getDataFromTree`', error);
          }
        }
      }

      // Extract query data from the Apollo store
      const apolloState = apolloClient.cache.extract();

      return {
        ...pageProps,
        apolloState
      };
    };
  }
  return WithApollo;
}

export function addApolloState<T = any>(client: ApolloClient<NormalizedCacheObject>, pageProps: { props: T, revalidate?: number }) {
  if (pageProps?.props) {
    pageProps.props[APOLLO_STATE_PROP_NAME] = client.cache.extract();
  }
  return pageProps;
}

export function useApollo(pageProps) {
  const state = pageProps[APOLLO_STATE_PROP_NAME];
  const store = useMemo(() => initializeApollo(null, state), [state]);
  return store;
}

export const withApolloClient = (PageComponent) => {
  const WithApollo = ({ ...pageProps }) => {
    const client = useApollo(pageProps);

    return (
      <ApolloProvider client={client}>
        <AppWrapper>
          <PageComponent {...pageProps} />
        </AppWrapper>
      </ApolloProvider>
    );
  };
  return WithApollo;
};
