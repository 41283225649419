import CautionWarning from '@oneflare/flarekit/lib/components/icons/CautionWarning/CautionWarning';
import Success from '@oneflare/flarekit/lib/components/icons/Success';
import Information from '@oneflare/flarekit/lib/components/icons/Information';
import { DISMISS_UNVERIFIED_BANNER } from 'mutations/shared/businessVerification';

export const NOTIFICATION_STATUS = {
  SUCCESS: 'SUCCESS',
  WARNING: 'WARNING',
  INFO: 'INFO',
  ERROR: 'ERROR',
  ALERT: 'ALERT',
  DARK: 'ALERT'
};

export const iconTypeMapping = {
  [NOTIFICATION_STATUS.SUCCESS]: Success,
  [NOTIFICATION_STATUS.WARNING]: CautionWarning,
  [NOTIFICATION_STATUS.INFO]: Information
};

export const backgroundColorMapping = {
  [NOTIFICATION_STATUS.SUCCESS]: 'successDark',
  [NOTIFICATION_STATUS.WARNING]: 'warningDark',
  [NOTIFICATION_STATUS.INFO]: 'infoDark',
  [NOTIFICATION_STATUS.ERROR]: 'dangerDark',
  [NOTIFICATION_STATUS.ALERT]: 'charcoal',
  [NOTIFICATION_STATUS.dark]: 'charcoal'
};

export const mutationMap = {
  dismissUnverifiedBanner: DISMISS_UNVERIFIED_BANNER
};
