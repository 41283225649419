import { gql } from '@apollo/client';
import { ICategoryModel } from 'types/shared';

export const CATEGORY = gql`
query category($categorySlug: String, $identifier: ID) {
  category(categorySlug: $categorySlug, identifier: $identifier ) {
    id
    name
    noun
    jobName
    verb
    singular
    slug
    plural
    similarCategories {
      id
      name
      cachedSlug
    }
  }
}`;

export type CategoryResponse = {
  category: ICategoryModel
};
