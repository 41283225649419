import { gql } from '@apollo/client';

export const EMAIL_VALIDATION = gql`
  query validateEmail($email: String!) {
    validateEmail(email: $email) {
      validEmail
      emailExists
      firstName
      mobileVerificationRequired
    }
  }
`;

export type ValidateEmailResponse = {
  validateEmail: {
    validEmail: boolean;
    emailExists: boolean;
    firstName: string;
    mobileVerificationRequired: boolean;
  };
}
