import { useState } from 'react';
import { useMutation } from '@apollo/client';
import PropTypes from 'prop-types';
import { DISMISS_UNVERIFIED_BANNER } from 'mutations/shared/businessVerification';
import { useHandleMutationAlert } from 'shared/utils/hooks';
import {
  NotificationBannerStyled,
  SuccessIconStyled,
  WarningIconStyled,
  InformationIconStyled,
  AlertIconStyled,
  CloseIconStyled
} from './styled/NotificationBannerStyled';
import { NOTIFICATION_STATUS, mutationMap } from './utils';

const NotificationBanner = ({
  status, children, className, closable, action
}) => {
  const [display, setDisplay] = useState('flex');

  const [dismissBanner] = useMutation(
    mutationMap[action] || DISMISS_UNVERIFIED_BANNER
  );
  const [handleAlert] = useHandleMutationAlert();

  const IconMapping = {
    [NOTIFICATION_STATUS.SUCCESS]: SuccessIconStyled,
    [NOTIFICATION_STATUS.WARNING]: WarningIconStyled,
    [NOTIFICATION_STATUS.INFO]: InformationIconStyled,
    [NOTIFICATION_STATUS.ALERT]: AlertIconStyled
  };

  const IconStyled = IconMapping[status];

  const handleCloseClick = async () => {
    setDisplay('none');

    if (action) {
      const { errors } = dismissBanner();
      handleAlert({
        errors,
        datadogRumMsg: 'Oneflare | Shared | DISMISS_UNVERIFIED_BANNER mutation'
      });
    }
  };

  return (
    <NotificationBannerStyled className={className} display={display} status={status}>
      {IconStyled && <IconStyled /> }
      {children}
      {closable && (
        <div onClick={handleCloseClick} aria-hidden="true">
          <CloseIconStyled />
        </div>
      )}
    </NotificationBannerStyled>
  );
};

NotificationBanner.propTypes = {
  action: PropTypes.oneOf([...Object.keys(mutationMap)]),
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  closable: PropTypes.bool,
  status: PropTypes.oneOf(Object.values(NOTIFICATION_STATUS))
};

NotificationBanner.defaultProps = {
  action: 'dismissUnverifiedBanner',
  className: '',
  closable: false,
  status: NOTIFICATION_STATUS.ERROR
};

export default NotificationBanner;
