import { styled, css } from 'styled-components';
import media from '@oneflare/flarekit/lib/libs/media';
import Success from '@oneflare/flarekit/lib/components/icons/Success';
import CautionWarning from '@oneflare/flarekit/lib/components/icons/CautionWarning';
import Information from '@oneflare/flarekit/lib/components/icons/Information';
import No from '@oneflare/flarekit/lib/components/icons/No';
import Close from '@oneflare/flarekit/lib/components/icons/Close';
import Anchor from '@oneflare/flarekit/lib/components/Anchor';
import { backgroundColorMapping } from '../utils';

export const NotificationBannerStyled = styled.section`
  background-color: ${({ theme, status }) => theme.color[backgroundColorMapping[status]]};
  align-items: center;
  display: ${({ display }) => display};
  width: 100%;
  padding: 16px 20px;
  margin: 1px 0 0;
  position: relative;
  top: -1px;

  a {
    color: ${({ theme }) => theme.color.white};
  }

  ${media.lgUp`
    padding: 16px 40px;
  `}
`;

const basicIconStyle = css`
  display: none;
  ${media.mdUp`
    display: block;
    margin: 0 14px 0 0px;
    position: relative;
    top: 2px;

    svg {
      background-color: transparent;
      height: 24px;
      max-width: 24px;
    }
  `}
`;

export const SuccessIconStyled = styled(Success).attrs(({ theme }) => ({
  fill: theme.color.white,
  kind: 'hollow'
}))`
  ${basicIconStyle}
`;

export const WarningIconStyled = styled(CautionWarning).attrs(({ theme }) => ({
  fill: theme.color.white,
  kind: 'hollow'
}))`
  ${basicIconStyle}
`;

export const AlertIconStyled = styled(No).attrs(({ theme }) => ({
  fill: theme.color.white,
  kind: 'hollow'
}))`
  ${basicIconStyle}
`;

export const InformationIconStyled = styled(Information).attrs(({ theme }) => ({
  fill: theme.color.white,
  kind: 'solid'
}))`
  ${basicIconStyle}
`;

export const CloseIconStyled = styled(Close).attrs(({ theme }) => ({
  fill: theme.color.white,
  kind: 'hollow'
}))`
  ${basicIconStyle}

  svg {
    height: 15px;
    max-width: 15px;
  }

  &:hover {
    cursor: pointer;
  }
`;

export const NotificationBannerMsgStyled = styled.div`
  color: ${({ theme }) => theme.color.white};
  line-height: 1.5;
  margin-bottom: 0;
  font-size: 16px;
  flex: 1;
`;

export const NotificationLinkStyled = styled(Anchor)`
  color: ${({ theme }) => theme.color.white};
  text-decoration: underline;

  &:hover {
    color: ${({ theme }) => theme.color.white};
  }
`;
