import { ThemeProvider } from 'styled-components';
import oneflareTheme from '@oneflare/flarekit/lib/styles/styled-component/oneflare';
import weddingTheme from '@oneflare/flarekit/lib/styles/styled-component/wedding';
import { getDomain } from 'lib/utils/Environment';
import GlobalStyles from 'shared/styles/globalStyles';
import NotificationBanner from 'shared/components/NotificationBanner';
import { NOTIFICATION_STATUS } from 'shared/components/NotificationBanner/utils';
import { NotificationBannerMsgStyled, NotificationLinkStyled } from 'shared/components/NotificationBanner/styled/NotificationBannerStyled';
import { browserVersion, isIE } from 'react-device-detect';
import { type DefaultTheme } from 'styled-components/dist/types';

const themeMap = {
  oneflare: oneflareTheme,
  wedding: weddingTheme
};
const DOMAIN = getDomain();
const THEME = (themeMap[DOMAIN] || oneflareTheme) as DefaultTheme;

const isIE11 = isIE && browserVersion === '11';

const AppWrapper = ({ children }) => (
  <ThemeProvider theme={THEME}>
    <>
      <GlobalStyles />
      {isIE11 && (
        <NotificationBanner status={NOTIFICATION_STATUS.ALERT}>
          <NotificationBannerMsgStyled>
            {'Your browser is out of date. We recommend you '}
            <NotificationLinkStyled href="https://browsehappy.com" target="_blank">upgrade</NotificationLinkStyled>
            {' to have the best possible experience on our site.'}
          </NotificationBannerMsgStyled>
        </NotificationBanner>
      )}
      {children}
    </>
  </ThemeProvider>
);

export default AppWrapper;
