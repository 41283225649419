import { Component, ComponentType, useContext } from 'react';
import { DefaultTheme, ThemeContext, ThemeProvider } from 'styled-components';
import oneflareTheme from '@oneflare/flarekit/lib/styles/styled-component/oneflare';
import weddingTheme from '@oneflare/flarekit/lib/styles/styled-component/wedding';
import { publicRuntimeConfig } from 'lib/utils/Environment';

const themeMap = {
  oneflare: oneflareTheme,
  wedding: weddingTheme
};

const withThemeProvider = <P extends object>(
  WrappedComponent: ComponentType<P>
) => {
  return class withThemeProvider extends Component<P> {
    render() {
      const props = { ...this.props };
      return (
        <ThemeProvider theme={themeMap[publicRuntimeConfig.DOMAIN as string] || oneflareTheme}>
          <WrappedComponent
            {...props as P}
          />
        </ThemeProvider>
      );
    }
  };
};

export const useThemeContext = () => {
  const theme = useContext<DefaultTheme>(ThemeContext);

  return theme;
};

export default withThemeProvider;
