import AlertContainer from '@oneflare/flarekit/lib/components/AlertContainer';
import { createContext, MutableRefObject } from 'react';

interface PageContextModel {
  category?: any;
  location?: any;
  params?: Record<string, any>;
  AlertContainer: MutableRefObject<AlertContainer> | null;
  dynamicPageData?: Record<any, any>;
}

const PageContext = createContext<PageContextModel>({
  AlertContainer: null,
  category: null,
  location: null,
  dynamicPageData: {},
  params: {}
});

export { PageContext, type PageContextModel };
